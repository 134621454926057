import React, { useLayoutEffect, useState } from 'react';
import { Helmet } from "react-helmet"

import * as s from './contact.module.scss';

import Nav from '../components/Nav';
import Hero from '../components/Hero';
import Footer from '../components/Footer';
import crownFavicon from '../images/crown.png';
import heroImageCut from '../images/hero-websites-design-cut.png'
import Head from '../components/Head';

export default function HomePage() {
    useLayoutEffect(() => {
        document.getElementById('phone-placeholder').innerHTML = atob('KzMzIDcgNzEgNjAgNTMgOTc=');
        document.getElementById('email-placeholder').innerHTML = atob("Y29udGFjdEB3ZWJzaXRlcy1kZXNpZ24ucHJv");
    }, [])

    return (
        <div>
            <Head>
                <title>Créations et design de site webs - Websites & Design</title>
            </Head>
            <Nav />
            <Hero smallTitle>
                <h1>Contact & Infos de contact</h1>
            </Hero>

            <div id={s.content} className="container">
                {/*<div id={s.contentForm}>
                    <form>
                        <input
                            className={`${s.email} ${s.input}`}
                            placeholder="Your email"
                            type="text"
                        />

                        <input
                            className={`${s.name} ${s.input}`}
                            placeholder="Your name"
                            type="text"
                        />

                        <textarea
                            className={`${s.msg} ${s.textarea}`}
                            placeholder="Your message"
                            rows={16}
                        />

                        <button className={s.submitBtn} type="submit">
                            Envoyer
                        </button>
                    </form>
                </div>*/}

                <div id={s.contactInfos}>
                    <p className={s.contactInfoTitle}>
                        Email de contact
                    </p>

    
                    <p id="email-placeholder" className={s.contactInfo}>
                        
                    </p>

                    <p className={s.contactInfoTitle}>
                        Téléphone de contact
                    </p>

                    <p id="phone-placeholder" className={s.contactInfo}>
                        
                    </p>
                    
                </div>
            </div>

            <Footer />
        </div>
    )
}